import React, { useState, useEffect } from "react";
export default function Success() {
    return (
        <>
            <div className="success-wrapper">
                <div className="success-title">Zahvaljujemo se na narudžbi!</div>
                <div className="success-messagebox">
                    Vaša narudžba je zaprimljena. Podatke za uplatu ćemo poslati na Vašu email adresu.
                </div>
            </div>
        </>
    );
}