import Filter from './filter'
import { Route, Routes } from 'react-router-dom'
import Details from './details'
import Cart from './cart'
import Success from './success'


function App() {
  return (
    // <h1>hello2</h1>
    <Routes>
      <Route path='/' element={<Filter />} />
      <Route path='/details' element={<Details />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/success' element={<Success />} />
    </Routes>
  );

}



export default App;
