
import logo from './logo.svg';
import star from './full_star.svg';
import empty_star from './empty_star.svg';

import biodinamicki from "./biodinamicki_uzgoj.svg"
import kamera from "./kamera.svg"
import eko_certifikat from "./eko_certifikat.svg"
import eko_uzgoj from "./eko_uzgoj.svg"
import grijani_plastenik from "./grijani_plastenik.svg"
import navodnjavanje from "./navodnjavanje.svg"
import ograda from "./ograda.svg"
import plastenik from "./plastenik.svg"
import staklenik from "./staklenik.svg"
import zastitna_mreza from "./zastitna_mreza.svg"

import './App.css';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

export default function Details() {
    const location = useLocation()
    const p = location.state.package


    const [packages, setPackages] = useState([]);







    return (<>
        {/* <Link to='/' state={{ name: 'zayne' }}>{name}</Link> */}
        <PackageResultItem package1={p} />

    </>
    );
}


function handleClick(p) {
    const serializedObj = JSON.stringify(p);
    sessionStorage.setItem('cartItem', serializedObj);
}

function PackageResultItem({ package1 }) {
    const navigate = useNavigate();

    let p = package1;
    function calculatePrice() {
        let fixedFee = p.paymentTypes.filter(x => x.paymentId == 1)[0].fixedFee;
        let percentageFee = p.paymentTypes.filter(x => x.paymentId == 1)[0].percentageFee;

        let deliveryFee = p.deliveryTypes.filter(x => x.id == 2)[0].price;
        // console.log(p.paymentTypes);
        // console.log("cijena dostave: " + deliveryFee);
        // console.log(fixedFee);
        // console.log(percentageFee);
        let basePrice = p.price + deliveryFee;
        return (basePrice + fixedFee + (basePrice * percentageFee) / 100);
    }


    return (<div className='package-details-wrap'>
        <div className='pt-5 px-4 pb-0 package-details-item'>
            <div className='package-result-img3 d-flex justify-content-center align-items-baseline bg-white'>
                <img src={package1.image}></img>
            </div>
            <div className='package-result-details2 justify-content-start p-0'>
                <div className='package-result-name-details'>
                    <div className='producer-title'>{package1.businessName}</div>
                    <div></div>
                    <div className='starRating'>{getStars(package1.rating.toFixed(0))}</div>
                </div>
                <div className='package-result-description'>
                    {package1.producerDescription}
                </div>
                {/* <div className='garden-price'>{(calculatePrice() / 7.53450).toFixed(2)} € mjesečno</div>
                <div className='garden-price-eur'>({calculatePrice()} kn mjesečno)</div> */}
                <div className='garden-price'>{(calculatePrice()).toFixed(2)} € mjesečno</div>
                <div className='garden-price-eur pb-2'>({((calculatePrice()).toFixed(2) * 7.53450).toFixed(2)} kn mjesečno)</div>
                {/* <div className='garden-price-note'>* iskazana cijena odnosi se na transakcijsko plaćanje i dostavu kurirskom službom. Cijene mogu biti veće ili manje od iskazanih ovisno o načinu plaćanja, broju mjesečnih rata i načinu dostave. Točna cijena paketa bit će prikazana u košarici.</div> */}
            </div>
        </div>
        <div className='garden-price-note'>* iskazana cijena odnosi se na jednokratno plaćanje transakcijskom uplatom i dostavu kurirskom službom. Cijene mogu biti veće ili manje od iskazanih ovisno o načinu plaćanja, broju mjesečnih rata i načinu dostave. Točna cijena paketa bit će prikazana u košarici.</div>
        <div className='garden-info-wrap'>
            <div className='garden-info'>Informacije o vrtu</div>
            <div className='garden-location-details'>
                <div className='garden-location'>Lokacija</div>
                <div className='garden-location-data'>{package1.location} <span>({package1.region} županija)</span></div>
            </div>
            <div className='garden-location-details'>
                <div className='garden-location'>Povrće</div>
                <div className='garden-location-data'>{package1.vegetableNames}</div>
            </div>
            <div className='garden-location-details'>
                <div className='garden-location'>Opis paketa</div>
                <div className='garden-location-data'>{package1.description}</div>
            </div>
            <div className='garden-location-details'>
                <div className='garden-location'>Dodatni elementi</div>
                <div className='garden-location-data'>
                    <div className='package-result-additional'>
                        <div className='package-result-additional-items'>
                            {
                                package1.additionalElements.length > 0 ? (
                                    <div className='additional-elements-wrapper'>
                                        {
                                            package1.additionalElements.map((p) =>
                                                <AdditionalItem element={p} />
                                            )
                                        }

                                    </div>
                                ) : (<div></div>)
                            }


                        </div>

                    </div>
                </div>
            </div>
            <div className='add-to-basket'>
                <button className='garden-details-button' onClick={() => { handleClick(package1); (navigate('/cart', { state: { package: package1 } })) }}>Dodaj u košaricu</button>
            </div>
        </div>
        <div className='eur-price text-center'>Tečaj €/kn: 7,53450</div>
    </div>
    );
}


function AdditionalItem({ element }) {
    let Component;
    let description = "";
    if (element.type == "eco") {
        Component = eko_uzgoj;
        description = "Proizvođač uzgaja povrće na ekološki način, ali ne posjeduje certifikat."
    }
    else if (element.type == "camera") {
        Component = kamera;
        description = "Ovaj vrt je pod videonadzorom što znači da vrt u svakom trenutku možete pratiti preko naše mobilne aplikacije."
    }
    else if (element.type == "net") {
        Component = zastitna_mreza;
        description = "Ovaj vrt ima postavljenu zaštitnu mrežu."
    }
    else if (element.type == "navodnjavanje") {
        Component = navodnjavanje;
        description = "Ovaj vrt ima sustav za navodnjavanje."
    }
    else if (element.type == "staklenik") {
        Component = staklenik;
        description = "U ovom paketu povrće se uzgaja u stakleniku."
    }
    else if (element.type == "plastenik") {
        Component = plastenik;
        description = "U ovom paketu povrće se uzgaja u plasteniku."
    }
    else if (element.type == "heated_plastenik") {
        Component = grijani_plastenik;
        description = "U ovom paketu povrće se uzgaja u grijanom plasteniku."
    }
    else if (element.type == "biodynamic") {
        Component = biodinamicki;
        description = "U ovom paketu, povrće se uzgaja na biodinamički način."
    }
    else if (element.type == "eco_certificate") {
        Component = eko_certifikat;
        description = "Ovaj vrt se uzgaja na ekološki način, a proizvođač posjeduje ekološki certifikat."
    }
    else if (element.type == "fenced") {
        Component = ograda;
        description = "Ovaj vrt je ograđen";
    }
    return (<>
        <ReactTooltip />
        <div className='garden-details-additional-elements' data-tip={description} data-delay-show='1000'>
            <div><img src={Component}></img></div>
            <div className='garden-details-additional-elements-text'>{element.displayName}</div>
        </div>
    </>
    );
}

function getStars(number) {
    if (number == 1) return (<>
        <div className='stars'>
            <img src={star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
        </div>
    </>);
    else if (number == 2) return (<>
        <div className='stars'>
            <img src={star}></img>
            <img src={star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
        </div>
    </>)
    else if (number == 3) return (<>
        <div className='stars'>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={empty_star}></img>
            <img src={empty_star}></img>
        </div>
    </>)
    else if (number == 4) return (<>
        <div className='stars'>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={empty_star}></img>
        </div></>)
    else if (number == 5) return (<>
        <div className='stars'>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
            <img src={star}></img>
        </div></>)
}
