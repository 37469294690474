import logo from './logo.svg';
import star from './full_star.svg';
import empty_star from './empty_star.svg';
import obiteljski from './obiteljski_paket.svg'
import mali from './mali_paket.svg'
import prilagodjeni from './prilagodjeni_paket.svg'
import biodinamicki from "./biodinamicki_uzgoj.svg"
import kamera from "./kamera.svg"
import eko_certifikat from "./eko_certifikat.svg"
import eko_uzgoj from "./eko_uzgoj.svg"
import grijani_plastenik from "./grijani_plastenik.svg"
import navodnjavanje from "./navodnjavanje.svg"
import ograda from "./ograda.svg"
import plastenik from "./plastenik.svg"
import staklenik from "./staklenik.svg"
import zastitna_mreza from "./zastitna_mreza.svg"
import loader from "./Spinner-5.gif"
import './App.css';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

//const API_URL = "https://localhost:5003/api/Package/filter2?";
import { API_URL } from './configurations.js';
function calculatePrice(p) {
  let quantity = 1;
  let fixedFee = p.paymentTypes.filter(x => x.paymentId == 2)[0].fixedFee;
  let percentageFee = p.paymentTypes.filter(x => x.paymentId == 2)[0].percentageFee;
  // console.log("delivry typ");
  // console.log(p.deliveryTypes.filter(x => x.id == 2));
  let onePriceForMultiplePackages = p.deliveryTypes.filter(x => x.id == 2)[0].onePriceForMultiplePackages;
  let deliveryFee = p.deliveryTypes.filter(x => x.id == 2)[0].price;
  if (onePriceForMultiplePackages == false) deliveryFee *= quantity;
  // console.log(p.paymentTypes);
  // console.log("cijena dostave: " + deliveryFee);
  // console.log(fixedFee);
  // console.log(percentageFee);
  let basePrice = quantity * p.price + deliveryFee;
  // console.log("ukupna cijena:");
  // console.log((basePrice + fixedFee + ((basePrice * percentageFee) / 100)));
  return (basePrice + fixedFee + ((basePrice * percentageFee) / 100));
}

export default function Filter() {


  const [selectedPackageType, setSelectedPackageType] = useState("family");
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  const paketi = [
    {
      "packageId": "3557cb85-f784-4995-92c7-2c52ee373efa",
      "businessName": "Jagoda Market D.O.O",
      "location": "Varaždinska",
      "producerDescription": "",
      "producerId": "KCvndEs22bb7T8N8PnFnWztOBzS2",
      "vegetableNames": "Paprika",
      "name": "family",
      "description": "Ob. paket: It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "price": 40,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "",
      "available": 7,
      "vegetables": [
        {
          "id": "74638b8a-803d-40b8-95a0-f1b127970768",
          "name": "Paprika",
          "kind": "Babura",
          "fieldId": "4518aa77-bc1f-4170-8ac8-4d2d0f6218fd",
          "availableAmount": 0,
          "price": 0,
          "fee": 0
        }
      ],
      "additionalElements": [
        {
          "id": 2,
          "type": "camera",
          "imagePath": "kamera.svg",
          "displayName": "Nadzorna kamera"
        },
        {
          "id": 1,
          "type": "eco",
          "imagePath": "eko.svg",
          "displayName": "Eko uzgoj"
        },
        {
          "id": 3,
          "type": "net",
          "imagePath": "net.svg",
          "displayName": "Zaštitna mreža"
        },
        {
          "id": 4,
          "type": "navodnjavanje",
          "imagePath": "navodnjavanje.svg",
          "displayName": "Navodnjavanje"
        }
      ],
      "fieldId": "4518aa77-bc1f-4170-8ac8-4d2d0f6218fd",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    },
    {
      "packageId": "3d57c8fa-19c0-40b5-8aab-cfe2968e4e47",
      "businessName": "Tvinkovi",
      "location": "Vukovarsko-srijemska",
      "producerDescription": "",
      "producerId": "J1MshkOzpTPIZHWGGztHaIQnaz62",
      "vegetableNames": "Paprika",
      "name": "family",
      "description": "ob. paket",
      "price": 12.5,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "",
      "available": 1,
      "vegetables": [
        {
          "id": "6cd82bf2-ab5e-4b7f-8cd1-b7020003e2e6",
          "name": "Paprika",
          "kind": "Babura",
          "fieldId": "8a4bc672-1dc4-47e0-a49f-e6b23fee07ec",
          "availableAmount": 0,
          "price": 0,
          "fee": 0
        }
      ],
      "additionalElements": [
        {
          "id": 2,
          "type": "camera",
          "imagePath": "kamera.svg",
          "displayName": "Nadzorna kamera"
        },
        {
          "id": 1,
          "type": "eco",
          "imagePath": "eko.svg",
          "displayName": "Eko uzgoj"
        }
      ],
      "fieldId": "8a4bc672-1dc4-47e0-a49f-e6b23fee07ec",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    },
    {
      "packageId": "44ccb121-3749-4350-acb8-8908a2927a84",
      "businessName": "Marko Petrovic d.o.o.",
      "location": "Osječko-baranjska",
      "producerDescription": "",
      "producerId": "Ybo066C9fXOFqnBoi9BxUwLfv0x2",
      "vegetableNames": "Rajčica, Rajčica, Paprika",
      "name": "family",
      "description": "Obiteljski paket",
      "price": 6.5,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "",
      "available": 5,
      "vegetables": [
        {
          "id": "3a4158f4-c43e-48f1-b3b0-35d7e902cdb3",
          "name": "Rajčica",
          "kind": "Grappolo",
          "fieldId": "ccba3ea5-1258-440f-83b4-2ebbdbb301fd",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        },
        {
          "id": "44a3fc75-af16-4189-84ed-15c0294f6ea2",
          "name": "Rajčica",
          "kind": "Cherry",
          "fieldId": "ccba3ea5-1258-440f-83b4-2ebbdbb301fd",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        },
        {
          "id": "be551a45-c944-44db-a22c-662de12cc19a",
          "name": "Paprika",
          "kind": "Babura",
          "fieldId": "ccba3ea5-1258-440f-83b4-2ebbdbb301fd",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        }
      ],
      "additionalElements": [
        {
          "id": 1,
          "type": "eco",
          "imagePath": "eko.svg",
          "displayName": "Eko uzgoj"
        }
      ],
      "fieldId": "ccba3ea5-1258-440f-83b4-2ebbdbb301fd",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    },
    {
      "packageId": "48bd86c1-3b08-462f-9dfb-7f8a3ba92294",
      "businessName": "Palinkaš",
      "location": "",
      "producerDescription": "Best in da town: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      "producerId": "fehJ8mNqWUSbRICYXBkDeoWsS5p1",
      "vegetableNames": "",
      "name": "family",
      "description": "Da best paket: It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "price": 465,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "kolar.jpg",
      "available": 3,
      "vegetables": [],
      "additionalElements": [],
      "fieldId": "35b9533f-1178-4552-8955-78a3dd551a56",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    },
    {
      "packageId": "7adfb03c-836c-4ae5-8219-e73d1b37b225",
      "businessName": "OPG Salai",
      "location": "Osječko-baranjska",
      "producerDescription": "",
      "producerId": "Sly6T26gytPphuF2V4mguRbKcDq1",
      "vegetableNames": "Patlidžan",
      "name": "family",
      "description": "ovo je opis mog paketa",
      "price": 13,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "",
      "available": 1,
      "vegetables": [
        {
          "id": "31684d49-9f7e-48ab-a0aa-ec63083f8a07",
          "name": "Patlidžan",
          "kind": "Bijela domaća",
          "fieldId": "94949e38-2317-40e0-a15b-ae9c9d2ec5d3",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        }
      ],
      "additionalElements": [
        {
          "id": 10,
          "type": "heated_plastenik",
          "imagePath": "heated_plastenik.svg",
          "displayName": "Grijani plastenik"
        },
        {
          "id": 12,
          "type": "eco_certificate",
          "imagePath": "eco_certificate.svg",
          "displayName": "Eko certifikat"
        },
        {
          "id": 6,
          "type": "staklenik",
          "imagePath": "staklenik.svg",
          "displayName": "Staklenik"
        },
        {
          "id": 2,
          "type": "camera",
          "imagePath": "kamera.svg",
          "displayName": "Nadzorna kamera"
        },
        {
          "id": 4,
          "type": "navodnjavanje",
          "imagePath": "navodnjavanje.svg",
          "displayName": "Navodnjavanje"
        }
      ],
      "fieldId": "94949e38-2317-40e0-a15b-ae9c9d2ec5d3",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    },
    {
      "packageId": "94fd1e1e-f27c-475f-a5e2-c65d6ea6e380",
      "businessName": "Jabuke Obrt",
      "location": "Vukovarsko-srijemska",
      "producerDescription": "",
      "producerId": "zw37RjCs3oeiNoTPGOfEz8s4Qxm2",
      "vegetableNames": "Paprika, Paprika",
      "name": "family",
      "description": "ob. paket",
      "price": 13,
      "smallestSize": 0,
      "largestSize": 0,
      "image": "",
      "available": 10,
      "vegetables": [
        {
          "id": "6bdf4a7f-9543-4f9f-9e34-190105cb2752",
          "name": "Paprika",
          "kind": "Babura",
          "fieldId": "1eaa0006-7a06-4a26-9387-ac8f6a262bd0",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        },
        {
          "id": "7777e76a-021b-4264-851a-a05757227143",
          "name": "Paprika",
          "kind": "Beef",
          "fieldId": "1eaa0006-7a06-4a26-9387-ac8f6a262bd0",
          "availableAmount": 0,
          "price": 0,
          "fee": 0.3
        }
      ],
      "additionalElements": [
        {
          "id": 2,
          "type": "camera",
          "imagePath": "kamera.svg",
          "displayName": "Nadzorna kamera"
        }
      ],
      "fieldId": "1eaa0006-7a06-4a26-9387-ac8f6a262bd0",
      "paymentTypes": [
        {
          "id": "transakcijski",
          "paymentId": 1,
          "paymentName": "Jednokratno transakcijski",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično",
          "paymentId": 2,
          "paymentName": "Jednokratno kartično",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kripto",
          "paymentId": 3,
          "paymentName": "Kriptovalutama",
          "percentageFee": 0.5,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 1
        },
        {
          "id": "kartično-rate6",
          "paymentId": 4,
          "paymentName": "Kartično na 6 rata",
          "percentageFee": 0.7,
          "fixedFee": 0.2,
          "numberOfRecuringPayments": 6
        },
        {
          "id": "kartično-rate12",
          "paymentId": 5,
          "paymentName": "Kartično na 12 rata",
          "percentageFee": 0.7,
          "fixedFee": 200,
          "numberOfRecuringPayments": 12
        }
      ]
    }
  ]

  const packages2 = [{
    "packageId": "3557cb85-f784-4995-92c7-2c52ee373efa",
    "businessName": "Jagoda Market D.O.O",
    "location": "Varaždinska",
    "producerDescription": "",
    "producerId": "KCvndEs22bb7T8N8PnFnWztOBzS2",
    "vegetableNames": "Paprika",
    "name": "family",
    "description": "Ob. paket: It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    "price": 40,
    "smallestSize": 0,
    "largestSize": 0,
    "image": "",
    "available": 7,
    "vegetables": [
      {
        "id": "74638b8a-803d-40b8-95a0-f1b127970768",
        "name": "Paprika",
        "kind": "Babura",
        "fieldId": "4518aa77-bc1f-4170-8ac8-4d2d0f6218fd",
        "availableAmount": 0,
        "price": 0,
        "fee": 0
      }
    ],
    "additionalElements": [
      {
        "id": 2,
        "type": "camera",
        "imagePath": "kamera.svg",
        "displayName": "Nadzorna kamera"
      },
      {
        "id": 1,
        "type": "eco",
        "imagePath": "eko.svg",
        "displayName": "Eko uzgoj"
      },
      {
        "id": 3,
        "type": "net",
        "imagePath": "net.svg",
        "displayName": "Zaštitna mreža"
      },
      {
        "id": 4,
        "type": "navodnjavanje",
        "imagePath": "navodnjavanje.svg",
        "displayName": "Navodnjavanje"
      }
    ],
    "fieldId": "4518aa77-bc1f-4170-8ac8-4d2d0f6218fd",
    "paymentTypes": [
      {
        "id": "transakcijski",
        "paymentId": 1,
        "paymentName": "Jednokratno transakcijski",
        "percentageFee": 0.5,
        "fixedFee": 0.2,
        "numberOfRecuringPayments": 1
      },
      {
        "id": "kartično",
        "paymentId": 2,
        "paymentName": "Jednokratno kartično",
        "percentageFee": 0.7,
        "fixedFee": 0.2,
        "numberOfRecuringPayments": 1
      },
      {
        "id": "kripto",
        "paymentId": 3,
        "paymentName": "Kriptovalutama",
        "percentageFee": 0.5,
        "fixedFee": 0.2,
        "numberOfRecuringPayments": 1
      },
      {
        "id": "kartično-rate6",
        "paymentId": 4,
        "paymentName": "Kartično na 6 rata",
        "percentageFee": 0.7,
        "fixedFee": 0.2,
        "numberOfRecuringPayments": 6
      },
      {
        "id": "kartično-rate12",
        "paymentId": 5,
        "paymentName": "Kartično na 12 rata",
        "percentageFee": 0.7,
        "fixedFee": 200,
        "numberOfRecuringPayments": 12
      }
    ]
  }];



  useEffect(() => {
    //tu ide kod koji trebam loadati cim se program pokrene
    //setPackages(paketi);
    searchPackages('family');

  }, []);

  const searchPackages = async (packageType) => {
    setLoading(true);
    const response = await fetch(`${API_URL}&package=${packageType}`);
    const data = await response.json();

    setSelectedPackageType(packageType);
    setPackages(data);
    setLoading(false);
  };

  function ButtonHit() {
    // console.log("hitting");
  }

  // console.log(selectedPackageType);
  return (
    <div className="App">
      <ReactTooltip />
      {/* <Link to='/details' state={{ name: 'zayne' }}>klikni me</Link> */}
      {/* <div>
        <img src={logo} className="App-logo"></img>
        <span className='menu-item'>Početna</span>
        <span className='menu-item'>Trgovina</span>
        <span className='menu-item'>O nama</span>
      </div> */}
      <header className="App-header">
        {/* <div><span>{packages.length} pronađenih paketa</span><input value="" placeholder='Pretraga'></input><button>Poredaj</button></div> */}
        <div className='outer-box'>
          <div className="packages">
            <div className={selectedPackageType == "small" ? 'package-item-selected' : 'package-item'} data-tip="Paketi prilagođeni za jednu do dvije osobe." data-delay-show='1000' onClick={() => searchPackages("small")}>
              <div><img src={mali}></img></div>
              <div><span>Mali paket</span></div></div>
            <div className={selectedPackageType == "family" ? 'package-item-selected' : 'package-item'} data-tip="Paketi prilagođeni za obitelj veličine 3-4 člana." data-delay-show='1000' onClick={() => searchPackages("family")}>
              <div><img src={obiteljski}></img></div>
              <div> <span>Obiteljski paket</span></div>
            </div>

            <div className={selectedPackageType == "custom" ? 'package-item-selected' : 'package-item'} data-tip="Paketi koji Vam omogućavaju da sami kreirate svoj vrt." data-delay-show='1000' onClick={() => searchPackages("custom")}>
              <div><img src={prilagodjeni}></img></div>
              <div><span>Prilagođeni paket</span></div></div>
          </div>
          <div className='results'>
            {
              loading == false ?
                packages.length > 0 ? (
                  <div>
                    {
                      packages.map((p) =>
                        <PackageResultItem package1={p} />
                      )
                    }
                    <div className='eur-price text-center'>Tečaj €/kn: 7,53450</div>
                  </div>
                ) : (<div className='pacakge-result-item'>
                  <div className='no-packages-found'>Trenutno nema odabranih paketa ili su svi paketi rasprodani.</div>
                </div>) : <div className='loader'><img src={loader}></img></div>
            }
          </div>
        </div>
      </header>
    </div>
  );
}

function PackageResultItem({ package1 }) {
  const navigate = useNavigate();
  return (
    <div className='package-result-item' onClick={() => navigate('/details', { state: { package: package1 } })}>
      <div className='package-result-img'>
        <img src={package1.image}></img>
      </div>
      <div className='package-result-details'>
        <div className='package-result-name-details'>
          <div className='name-details-producer'>{package1.businessName}<span>  - {package1.location}</span></div>
          <div className='name-details-star-rating'>{getStars(package1.rating.toFixed(0))}</div>
        </div>
        <div className='package-result-description'>
          {package1.description}
        </div>
        <div className='package-result-additional'>
          <div className='package-result-additional-items'>
            {
              package1.additionalElements.length > 0 ? (
                <div>
                  {
                    package1.additionalElements.map((p) =>
                      <AdditionalItem element={p} />
                    )
                  }

                </div>
              ) : (<div></div>)
            }


          </div>
          <div className='price-wrapper'>
            {/* <div className='package-result-price'>{(package1.price / 7.5345).toFixed(2)} - {(calculatePrice(package1) / 7.5345).toFixed(2)} € mjesečno</div>
            <div className='eur-price'>({package1.price.toFixed(2)} - {calculatePrice(package1).toFixed(2)} kn mjesečno)</div> */}
            <div className='package-result-price'>{(package1.price).toFixed(2)} - {(calculatePrice(package1)).toFixed(2)} € mjesečno</div>
            <div className='eur-price'>({(package1.price.toFixed(2) * 7.5345).toFixed(2)} - {((calculatePrice(package1)).toFixed(2) * 7.53450).toFixed(2)} kn mjesečno)</div>
          </div>
          {/* <div className='package-result-price'>{calculatePrice(package1)} kn</div> */}
        </div>
      </div>
    </div>
  );
}

function AdditionalItem({ element }) {
  let Component;
  let description = "";
  if (element.type == "eco") {
    Component = eko_uzgoj;
    description = "Proizvođač uzgaja povrće na ekološki način, ali ne posjeduje certifikat."
  }
  else if (element.type == "camera") {
    Component = kamera;
    description = "Ovaj vrt je pod videonadzorom što znači da vrt u svakom trenutku možete pratiti preko naše mobilne aplikacije."
  }
  else if (element.type == "net") {
    Component = zastitna_mreza;
    description = "Ovaj vrt ima postavljenu zaštitnu mrežu."
  }
  else if (element.type == "navodnjavanje") {
    Component = navodnjavanje;
    description = "Ovaj vrt ima sustav za navodnjavanje."
  }
  else if (element.type == "staklenik") {
    Component = staklenik;
    description = "U ovom paketu povrće se uzgaja u stakleniku."
  }
  else if (element.type == "plastenik") {
    Component = plastenik;
    description = "U ovom paketu povrće se uzgaja u plasteniku."
  }
  else if (element.type == "heated_plastenik") {
    Component = grijani_plastenik;
    description = "U ovom paketu povrće se uzgaja u grijanom plasteniku."
  }
  else if (element.type == "biodynamic") {
    Component = biodinamicki;
    description = "U ovom paketu, povrće se uzgaja na biodinamički način."
  }
  else if (element.type == "eco_certificate") {
    Component = eko_certifikat;
    description = "Ovaj vrt se uzgaja na ekološki način, a proizvođač posjeduje ekološki certifikat."
  }
  else if (element.type == "fenced") {
    Component = ograda;
    description = "Ovaj vrt je ograđen";
  }
  return (<><ReactTooltip />

    <img src={Component} data-tip={description} data-delay-show='1000'></img>

  </>
  );
}


function getStars(number) {
  if (number == 1) return (<>
    <div className='stars'>
      <img src={star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
    </div>
  </>);
  else if (number == 2) return (<>
    <div className='stars'>
      <img src={star}></img>
      <img src={star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
    </div>
  </>)
  else if (number == 3) return (<>
    <div className='stars'>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
      <img src={empty_star}></img>
      <img src={empty_star}></img>
    </div>
  </>)
  else if (number == 4) return (<>
    <div className='stars'>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
      <img src={empty_star}></img>
    </div></>)
  else if (number == 5) return (<>
    <div className='stars'>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
      <img src={star}></img>
    </div></>)
}
