
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { findAllInRenderedTree } from 'react-dom/test-utils';



export default function Cart() {
    const location = useLocation()
    const navigate = useNavigate();

    let p = getPackage();//location.state.package



    const [packages, setPackages] = useState([]);
    //const [quantity, setQuantity] = useState(1);
    //const [paymentTypeId, setPaymentTypeId] = useState(1);
    //const [deliveryTypeId, setDeliveryTypeId] = useState(1);
    //const [fullName, setFullName] = useState('');
    //const [address, setAddress] = useState('');
    //const [city, setCity] = useState('');
    //const [zip, setZip] = useState('');
    //const [phone, setPhone] = useState('');
    // const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const activeClass = "circle active1";
    const inactiveClass = "circle";

    const normalStateClass = "basket-input";
    const erorrStateClass = "basket-input error";

    const normalStateDeliveryClass = "dropdown";
    const errorStateDeliveryClass = "dropdown error";

    const [nameErrorMsg, setNameErrorMsg] = useState('');
    const [addressErrorMsg, setAddressErrorMsg] = useState('');
    const [cityErrorMsg, setCityErrorMsg] = useState('');
    const [zipErrorMsg, setZipErrorMsg] = useState('');
    const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [deliveryErrorMsg, setDeliveryErrorMsg] = useState('');
    const [deliveryMethodSet, setDeliveryMethodSet] = useState(false);
    const [agreementErrorMsg, setAgreementErrorMsg] = useState('');
    const [agreementSet, setAgreementSet] = useState(false);

    const dropdownRef = useRef();


    const [formData, setFormData] = useState({
        "quantity": 1,
        "paymentType": '',
        "fullName": '',
        "address": '',
        "city": '',
        "zip": '',
        "phone": '',
        "email": '',
        "deliveryType": 1,
        "lastPackageId": 'sdfkljds',
        "paymentType": 1
    });

    // const options = [
    //     'Osobni prikup od strane kupca', 'Dostava kurirskom službom DPD', 'Dostava od strane proizvođača', 'Dostava na punkt - BIOS', 'Dostava na punkt - Eko feferon'
    // ];
    // const defaultOption = options[0];
    // const options2 = [
    //     { value: 'one', label: 'One' },
    //     { value: 'two', label: 'Two', className: 'myOptionClassName' },
    // ];
    // const fromSession = readSessionVariables();

    let opt = getOptions();


    let defval;// = opt[0];




    function getPackage() {
        if (typeof p === "undefined") {
            // console.log("obj.prop is undefined");
            p = null;
        }

        try {
            var p = location.state.package;
            // console.log(p);
            // if (p == null) console.log("p je null u try bloku getpackage funkcije")
            if (p != null) {
                // console.log("p je uspjesno procitan iz location.state-a");
                return p;
            }
            // console.log("pe je null u getPackage try bloku");
            return null;
        }
        catch (e) {
            const serializedObj = sessionStorage.getItem('cartItem');
            // console.log("getPackage catch blok");
            //if (serializedObj == null) navigate('/');
            // console.log(serializedObj);
            const obj = JSON.parse(serializedObj);
            // console.log("obj u catch getpackage metode");
            // console.log(obj);
            if (obj == null) navigate('/');
            return obj;
        }
    }

    function getOptions() {

        // console.log(p);
        if (p == null) {

            // console.log("p je null u getOptions metodi");
            return null;
        }
        // console.log("getOptions proslo");
        // if (p == null || p == undefined) 
        //navigate('/');
        let options2 = p.deliveryTypes;
        let options3 = [];
        for (let i = 0; i < options2.length; i++) {

            options3.push(
                {
                    'value': options2[i].id, 'label': options2[i].description
                }
            );
        }
        // console.log(options3);
        return options3;
    }
    function clickHandler() {
        let error = false;
        if (formData.fullName == '') {
            error = true;
            setNameErrorMsg("Unesite ime i prezime");
        }
        else setNameErrorMsg('');

        if (formData.address == '') {
            error = true;
            setAddressErrorMsg("Unesite adresu");
        }
        else setAddressErrorMsg('');

        if (formData.city == '') {
            error = true;
            setCityErrorMsg("Unesite mjesto");
        }
        else setCityErrorMsg('');

        if (formData.zip == '') {
            error = true;
            setZipErrorMsg("Unesite poštanski broj");
        }
        else setZipErrorMsg('');

        if (formData.phone == '') {
            error = true;
            setPhoneErrorMsg("Unesite broj telefona");
        }
        else setPhoneErrorMsg('');

        if (formData.email == '') {
            error = true; setEmailErrorMsg("Unesite e-mail");
        }
        else setEmailErrorMsg('');

        if (password == '') {
            error = true; setPasswordErrorMsg("Unesite lozinku");
        }
        else setPasswordErrorMsg('');

        if (deliveryMethodSet == false) {
            error = true; setDeliveryErrorMsg("Odaberite način dostave");
        }

        if (agreementSet == false) {
            error = true; setAgreementErrorMsg("Morate prihvatiti uvjete korištenja");
        }

        if (error == false) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Origin": "https://localhost:3000" },
                body: JSON.stringify({
                    "fieldId": p.fieldId,
                    "packageId": p.packageId,
                    "customerId": p.customerid,
                    "quantity": formData.quantity,
                    "paymentType": formData.paymentType,
                    "fullName": formData.fullName,
                    "address": formData.address,
                    "city": formData.city,
                    "zip": formData.zip,
                    "phone": formData.phone,
                    "email": formData.email,
                    "password": password,
                    "deliveryType": formData.deliveryType
                })
            };
            fetch('https://backend.planton.me/api/package/OrderAnnonymous', requestOptions)
                .then((response) => {
                    if (response.ok) {
                        // console.log("sve je ok proslo");
                        // will succeed unless server logic or your logic is off
                        return response.json().then((data) => {
                            //window.location.reload();
                            // console.log("go to success now");
                            navigate('/success', {});
                        });
                    } else if (response.status === 400) {
                        // will succeed if the server will always respond with JSON with a 400 response
                        return response.json().then((errorObj) => setErrorMessage(errorObj.error));
                    } else {
                        // there was some other error in the response, such as status 500

                        // console.log(response.statusText);
                    }
                })
                .catch((err) => {
                    // An unexpected error occurred which was not 400 nor while parsing the response header
                    //console.log(err);

                });

        }
    }

    // Pozivamo useEffect hook da bismo se spojili na sessionStorage
    useEffect(() => {

        p = getPackage();//location.state.package
        // console.log("p u useeffect metodi:");
        // console.log(p);
        if (p == null) {
            // console.log("ovdje ne smije biti null jer inace vracam na pocetnu");
            return;
        }
        // console.log("ovo je paket---->");
        // console.log(p);
        // if (!location.state) {
        //     navigate('/');
        //     return;
        // }
        //let opt = getOptions();

        // Pročitajmo vrijednosti iz sessionStorage
        const formDataSession = sessionStorage.getItem('formData');

        // Ako postoje vrijednosti u sessionStorage, postavimo ih u stanje
        if (formDataSession) {
            setFormData(JSON.parse(formDataSession));
            var id = sessionStorage.getItem('lastPackageId');
            if (id != p.packageId) {
                // Zapisujemo vrijednost u session
                sessionStorage.setItem('formData', JSON.stringify({
                    ...formData,
                    quantity: 1
                }));

                // Mijenjamo vrijednost u stanju
                setFormData({
                    ...formData,
                    quantity: 1
                });
                sessionStorage.setItem("lastPackageId", p.packageId);
            }
        }
        else {
            sessionStorage.setItem("lastPackageId", p.packageId);
        }
    }, []);




    if (!location.state && sessionStorage.getItem('cartItem') == null) {
        return null;
    }

    function changeNameHandler(e) {
        //setFullName(e.target.value)
        if (e.target.value !== "") setNameErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            fullName: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            fullName: e.target.value
        });



        //saveToSession();
    }

    function changeAddressHandler(e) {
        // setAddress(e.target.value)
        if (e.target.value !== "") setAddressErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            address: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            address: e.target.value
        });


    }

    function changeCityHandler(e) {
        //setCity(e.target.value)
        if (e.target.value !== "") setCityErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            city: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            city: e.target.value
        });


    }

    function changeZipHandler(e) {
        //setZip(e.target.value)
        if (e.target.value !== "") setZipErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            zip: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            zip: e.target.value
        });


    }

    function changePhoneHandler(e) {
        //setPhone(e.target.value)
        if (e.target.value !== "") setPhoneErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            phone: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            phone: e.target.value
        });


    }

    function changeEmailHandler(e) {
        //setEmail(e.target.value)
        //if (e.target.value !== "") setEmailErrorMsg("");

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            email: e.target.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            email: e.target.value
        });

    }

    function changePasswordHandler(e) {
        setPassword(e.target.value)
        if (e.target.value !== "") setPasswordErrorMsg("");
    }

    //console.log(p);
    function findPaymentMethod() {
        return p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0];
    }

    function changePaymentTypeHandler(id) {

        //setPaymentTypeId(id) 

        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            paymentType: id
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            paymentType: id
        });

    }

    //console.log(p);
    function findPaymentMethod() {
        return p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0];
    }


    function calculatePrice() {
        let fixedFee = p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0].fixedFee;
        let percentageFee = p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0].percentageFee;
        // console.log("delivry typ");
        // console.log(p.deliveryTypes.filter(x => x.id == formData.deliveryType));
        let onePriceForMultiplePackages = p.deliveryTypes.filter(x => x.id == formData.deliveryType)[0].onePriceForMultiplePackages;
        let deliveryFee = p.deliveryTypes.filter(x => x.id == formData.deliveryType)[0].price;
        if (onePriceForMultiplePackages == false) deliveryFee *= formData.quantity;
        // console.log(p.paymentTypes);
        // console.log("cijena dostave: " + deliveryFee);
        // console.log(fixedFee);
        // console.log(percentageFee);
        let basePrice = formData.quantity * p.price + deliveryFee;
        return (basePrice + fixedFee + (basePrice * percentageFee) / 100);
    }

    function findPaymentMethodNumberOfPayments() {
        let num = p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0].numberOfRecuringPayments;
        let price = calculatePrice();

        if (num == 1) {
            return "(" + (price.toFixed(2) * 12).toFixed(2) + " kn)";
        }
        else {
            return ((price * 12).toFixed(2) + " kn (" + (price * 12 / num).toFixed(2)) + " kn/mj)";
        }
    }

    function findPaymentMethodNumberOfPaymentsEur() {
        let num = p.paymentTypes.filter(x => x.paymentId == formData.paymentType)[0].numberOfRecuringPayments;
        let price = calculatePrice();

        if (num == 1) {
            return ((calculatePrice() / 7.5345).toFixed(2) * 12).toFixed(2) + " €";
        }
        else {
            return (price * 12 / 7.5345).toFixed(2) + " € (" + (price / num * 12 / 7.5345).toFixed(2) + " €/mj)";
        }
    }

    function onSelect(e) {


        //setDeliveryTypeId(e.value);
        defval = e;
        // console.log("promjenjeno " + e.value);
        setDeliveryMethodSet(true);
        setDeliveryErrorMsg('');
        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            deliveryType: e.value
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            deliveryType: e.value
        });
        //saveToSession();
    }

    function onCheckboxSelect(e) {
        // console.log(e.target.checked);
        if (e.target.checked) {
            setAgreementSet(true);
            setAgreementErrorMsg('');
        }
        else {
            setAgreementSet(false);
        }
    }

    function decreaseQuantity() {
        if (formData.quantity > 1) {
            // Zapisujemo vrijednost u session
            sessionStorage.setItem('formData', JSON.stringify({
                ...formData,
                quantity: formData.quantity - 1
            }));

            // Mijenjamo vrijednost u stanju
            setFormData({
                ...formData,
                quantity: formData.quantity - 1
            });

        }

        //saveToSession();
    }
    function increaseQuantity() {
        // Zapisujemo vrijednost u session
        sessionStorage.setItem('formData', JSON.stringify({
            ...formData,
            quantity: formData.quantity + 1
        }));

        // Mijenjamo vrijednost u stanju
        setFormData({
            ...formData,
            quantity: formData.quantity + 1
        });


    }
    // console.log(findPaymentMethodNumberOfPayments());
    // console.log((2.555).toFixed(2));
    // console.log(p.packageId);

    // console.log("deliver metod set: " + deliveryMethodSet);
    return (<>
        {/* <Link to='/' state={{ name: 'zayne' }}>{name}</Link> */}
        <div className='body-wrapper-cart mx-0'>
            <div className='outer-wrapper'>
                <div className='basket-wrapper'>
                    <div className='basket-summary-text'>Košarica</div>
                    <div className='basket-details'>
                        <div className='basket-image'><img src={p.image}></img></div>
                        <div>
                            <div className='basket-summary-bold-label'>Vrt</div>
                            <div className='basket-summary-gray-label'>{p.name == "family" ? "Obiteljski paket" : "Mali paket"}</div>
                        </div>
                        <div>
                            <div className='basket-summary-bold-label'>{p.businessName}</div>
                            <div className='basket-summary-gray-label'>{p.location}</div>
                        </div>
                        <div className='basket-quantity'>
                            <button className="basket-increment-box" onClick={() => { decreaseQuantity() }}>-</button>
                            <div className='basket-summary-bold-label center-text'>{formData.quantity}</div>
                            <button className='basket-increment-box' onClick={() => { increaseQuantity() }}>+</button>
                        </div>
                        <div>
                            {/* <div className='basket-summary-bold-label'>{(((calculatePrice() / formData.quantity) / 7.5345).toFixed(2) * 12).toFixed(2)} €</div>
                            <div className='basket-summary-gray-label'>({((calculatePrice() / formData.quantity).toFixed(2) * 12).toFixed(2)}  kn)</div> */}
                            <div className='basket-summary-bold-label'>{((calculatePrice() / formData.quantity).toFixed(2) * 12).toFixed(2)} €</div>
                            <div className='basket-summary-gray-label'>({(((calculatePrice() / formData.quantity).toFixed(2) * 12).toFixed(2) * 7.53450).toFixed(2)}  kn)</div>
                        </div>
                    </div>
                    {/* <div className='basket-user-details'>
                        <div className='basket-user-data-label'>Ime i prezime:</div>
                        <div><input className='basket-input' /></div>
                    </div> */}
                    <div className='separator extra-margin'></div>
                    <div className='basket-user-details-wrapper'>
                        <div>
                            <div className='basket-summary-text'>Podaci o kupcu</div>
                            <div className='user-details extra-margin'>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Ime i prezime</div>
                                    <div className='basket-user-input-div'><input className={nameErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.fullName} name="fullName" onChange={(e) => { changeNameHandler(e) }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Adresa</div>
                                    <div className='basket-user-input-div'><input className={addressErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.address} name="address" onChange={(e) => { changeAddressHandler(e); }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Mjesto</div>
                                    <div className='basket-user-input-div'><input className={cityErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.city} name="city" onChange={(e) => { changeCityHandler(e) }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Poštanski broj</div>
                                    <div className='basket-user-input-div'><input className={zipErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.zip} name="zip" onChange={(e) => { changeZipHandler(e) }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Telefon</div>
                                    <div className='basket-user-input-div'><input className={phoneErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.phone} name="phone" onChange={(e) => { changePhoneHandler(e) }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>E-mail</div>
                                    <div className='basket-user-input-div'><input className={emailErrorMsg == "" ? normalStateClass : erorrStateClass} value={formData.email} name="email" onChange={(e) => { changeEmailHandler(e); }} /></div>
                                </div>
                                <div className='basket-user-details'>
                                    <div className='basket-user-data-label'>Lozinka</div>
                                    <div className='basket-user-input-div'><input className={passwordErrorMsg == "" ? normalStateClass : erorrStateClass} value={password} name="password" type="password" onChange={(e) => { changePasswordHandler(e); }} /></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='basket-summary-text'>Način plaćanja</div>
                            <div className='payment-types'>
                                {/* <div className='basket-user-data-label left-text' onClick={() => setPaymentTypeId(1)}>
                                    <div className={paymentTypeId == 1 ? activeClass : inactiveClass}></div>
                                    <div>Jednokrtna uplata - virman</div>
                                </div> */}
                                {p.paymentTypes.map((a) => <div className='basket-user-data-label left-text' onClick={() => { changePaymentTypeHandler(a.paymentId) }}><div className={formData.paymentType == a.paymentId ? activeClass : inactiveClass}></div>{a.paymentName}</div>)}

                                {/* <div className='basket-user-data-label left-text' onClick={() => setPaymentTypeId(3)}><div className={paymentTypeId == 3 ? activeClass : inactiveClass}></div>Jednokrtna uplata - kartično</div>
                                <div className='basket-user-data-label left-text' onClick={() => setPaymentTypeId(4)}><div className={paymentTypeId == 4 ? activeClass : inactiveClass}></div>Kartićno - 6 rata</div>
                                <div className='basket-user-data-label left-text' onClick={() => setPaymentTypeId(5)}><div className={paymentTypeId == 5 ? activeClass : inactiveClass}></div>Kartično - 12 rata</div> */}
                            </div>
                            <br />
                            <div className='basket-summary-text'>Način dostave</div>
                            <Dropdown ref={dropdownRef} className="dropdown" controlClassName={deliveryErrorMsg == '' ? '' : 'error'} options={opt} onChange={onSelect} value={defval} placeholder="Odaberi način dostave" />
                        </div>
                    </div>
                    {formData.paymentType == 2 && <div>
                        <div className='cards'>
                            <div>
                                <a href="https://www.mastercard.com">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/mc_vrt_opt_pos_46_2x.png'} alt="Slika" />
                                </a>
                            </div>
                            <div>
                                <a href='https://brand.mastercard.com/brandcenter/more-about-our-brands.html'>
                                    <img src={process.env.PUBLIC_URL + '/assets/img/ms_vrt_opt_pos_45_2x.png'} alt="Slika" />
                                </a>
                            </div>

                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/Discover50.gif'} alt="Slika" />
                            </div>
                            <div>
                                <a href='https://www.visaeurope.com'>
                                    <img src={process.env.PUBLIC_URL + '/assets/img/Visa 2015 50.gif'} alt="Slika" />
                                </a>
                            </div>
                        </div>
                        <div className='cards'>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/JCB50.gif'} alt="Slika" />
                            </div>
                            <div>
                                <a href="https://www.dinersclub.com">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/Diners50.gif'} alt="Slika" />
                                </a>
                            </div>
                            <div>
                                <a href="http://monri.com" target="blank">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/monri_footer.png'} alt="Slika" />
                                </a>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className='cards'>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/visa-secure_blu_72dpi.jpg'} alt="Slika" width={100} />
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/diners_sigurna kupnja.png'} alt="Slika" />
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/img/mc_idcheck_vrt_rgb_pos.png'} alt="Slika" width={100} />
                            </div>

                        </div>
                        <br />
                        <div className='basket-user-data-label text-center'>Vaše transakcije su sigurne sa Visa-om</div>
                    </div>}
                </div>
                <div className='basket-summary-wrapper'>
                    <div>
                        <div className='basket-summary-text'>Sažetak <span>({formData.quantity == 1 ? "1 paket" : formData.quantity + " paketa"})</span></div>
                        <div className='separator'></div>
                        <div className='basket-base-price'>
                            <div>
                                <div className='basket-summary-label'>Osnovica</div>
                                <div className='basket-summary-gray-label'>*U cijenu je uraćunata i dostava</div>
                            </div>
                            <div className=''>
                                {/* <div className='basket-summary-label'>{((calculatePrice() / 7.5345).toFixed(2) * 12).toFixed(2)} €</div>
                                <div className='basket-summary-gray-label'>({(calculatePrice().toFixed(2) * 12).toFixed(2)} kn)</div> */}
                                <div className='basket-summary-label'>{(calculatePrice().toFixed(2) * 12).toFixed(2)} €</div>
                                <div className='basket-summary-gray-label'>({((calculatePrice().toFixed(2) * 12).toFixed(2) * 7.53450).toFixed(2)} kn)</div>
                            </div>
                        </div>
                        <div className='basket-vat'>
                            <div className='basket-summary-label'>PDV</div>
                            <div className='basket-summary-label'>Nije u sustavu PDV-a</div>
                        </div>
                        <div className='separator'></div>
                        <div className='basket-final-price'>
                            <div className='basket-summary-bold-label'>Iznos</div>
                            <div>
                                {/* <div className='basket-summary-bold-label'>{findPaymentMethodNumberOfPaymentsEur()}</div>
                                <div className='basket-summary-gray-label'>{findPaymentMethodNumberOfPayments()}</div> */}
                                <div className='basket-summary-bold-label'>{(calculatePrice().toFixed(2) * 12).toFixed(2)} €</div>
                                <div className='basket-summary-gray-label'>({((calculatePrice().toFixed(2) * 12).toFixed(2) * 7.53450).toFixed(2)} kn)</div>
                            </div>
                        </div></div>
                    <div>
                        <div className='basket-terms'>
                            <div>
                                <input className={agreementErrorMsg == '' ? 'checkbox' : 'checkbox checkbox-error'} type="checkbox" onChange={onCheckboxSelect} />
                            </div>
                            <div className='basket-summary-gray-label'>Prihvaćam
                                <a href="https://planton.me/uvjeti_koristenja.html">uvjete korištenja</a>
                            </div>
                        </div>
                        <div>
                            <button className='basket-order-button' onClick={clickHandler}>Naruči</button>
                            {errorMessage && <div className='no-success'>{errorMessage}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='eur-price text-center'>Tečaj €/kn: 7,53450</div>
    </>


    );
}


